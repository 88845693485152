<!--
 * @Author: Link
 * @Date: 2021-01-11 10:27:10
 * @LastEditTime: 2021-03-23 14:51:32
-->
<template>
  <div class="related-area">
    <div class="searchContainer">
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商品ID</span>
          <el-input class="searchInput" v-model.trim="search.pid" placeholder="商品ID" clearable> </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商品名称</span>
          <el-input class="searchInput" v-model.trim="search.product" placeholder="商品名称" clearable> </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商家名称</span>
          <el-input class="searchInput" v-model.trim="search.business" placeholder="商家名称" clearable> </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8" :offset="16">
          <el-button type="info" @click="resetSearchRelateProduct">重置</el-button>
          <el-button
            type="primary"
            @click="
              pagination.page = 1
              getData()
            "
          >
            搜索</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="header">
      <div class="title">关联商品列表</div>
      <el-button type="success" @click="dialog.show = true">添加</el-button>
    </div>
    <el-table :data="table.list" style="margin-top:20px">
      <el-table-column
        align="center"
        v-for="col in Object.keys(table.label)"
        :key="col"
        :label="table.label[col]"
        :prop="col"
      >
        <template slot-scope="{ row }">
          <img class="product-img" v-if="col === 'imageUrl'" :src="row[col] | thumbSmall" />
          <template v-else>{{ row[col] }}</template>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button @click="action('delete', row)" type="text" size="small" class="deleteBtnText">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pagination.page"
        :page-size="pagination.size"
        :total="pagination.total"
        @current-change="
          v => {
            pagination.page = v
            getData()
          }
        "
        @size-change="
          v => {
            pagination.size = v
            pagination.page = 1
            getData()
          }
        "
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible="dialog.show"
      title="添加关联商品"
      @close="dialog.show = false"
      append-to-body
      width="70%"
      :close-on-click-modal="false"
    >
      <div class="dialogSearch">
        <el-row :gutter="20" class="query">
          <el-col :span="8">
            <span class="searchText">商品ID</span>
            <el-input class="searchInput" v-model.trim="dialog.search.productId" placeholder="商品ID" clearable>
            </el-input>
          </el-col>
          <el-col :span="8">
            <span class="searchText">商品名称</span>
            <el-input class="searchInput" v-model.trim="dialog.search.productName" placeholder="商品名称" clearable>
            </el-input>
          </el-col>
          <el-col :span="8">
            <span class="searchText">商家名称</span>
            <el-input class="searchInput" v-model.trim="dialog.search.businessName" placeholder="商家名称" clearable>
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="query">
          <el-col :span="8" :offset="16">
            <el-button type="info" @click="resetProductSearch">重置</el-button>
            <el-button
              type="primary"
              @click="
                dialog.pagination.page = 1
                getProductList()
              "
            >
              搜索</el-button
            ></el-col
          ></el-row
        >
      </div>

      <el-table
        height="400"
        :data="dialog.list"
        style="margin-top:20px"
        highlight-current-row
        @current-change="v => (dialog.selected = v)"
      >
        <el-table-column
          v-for="col in Object.keys(dialog.label)"
          :key="col"
          :prop="col"
          :label="dialog.label[col]"
          align="center"
        >
          <template slot-scope="{ row }">
            <img v-if="col === 'productUrl'" :src="row[col] | thumbMedium" class="img" />
            <template v-else-if="col === 'info'">
              <div>编号：{{ row.id }}</div>
              <div>{{ row.productName }}</div>
            </template>
            <template v-else-if="col === 'saleNum'">
              {{ calcSaleNum(row.productSkuList) }}
            </template>
            <template v-else-if="col === 'time'">
              <div>售卖开始：{{ row.saleTimeStart | formatTime }}</div>
              <div>售卖结束：{{ row.saleTimeEnd | formatTime }}</div>
              <div>下架时间：{{ row.writeOffTimeEnd | formatTime }}</div>
            </template>
            <template v-else>
              {{ row[col] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="dialog.pagination.page"
          :page-size="dialog.pagination.size"
          :total="dialog.pagination.total"
          @current-change="
            v => {
              dialog.pagination.page = v
              getProductList()
            }
          "
          @size-change="
            v => {
              dialog.pagination.size = v
              dialog.pagination.page = 1
              getProductList()
            }
          "
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="dialog.acting">确 定</el-button>
      </div>
    </el-dialog>
    <bm-deleteItem
      url="/boom-center-wechat-service/sysAdmin/wx/media/platform/associate/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
export default {
  props: ['wxPid'],
  data() {
    return {
      timestamp: 0,
      search: {
        pid: '',
        product: '',
        business: ''
      },
      dialog: {
        show: false,
        acting: false,
        search: {
          productId: '',
          productName: '',
          businessName: ''
        },
        selected: {},
        label: {
          productUrl: '商品主图',
          info: '商品信息',
          businessTitle: '商家名称',
          saleNum: '已售出',
          time: '时间'
        },
        list: [],
        pagination: {
          total: 0,
          size: 10,
          page: 1
        }
      },
      table: {
        label: {
          imageUrl: '商品主图',
          productId: '商品ID',
          productName: '商品名称',
          businessName: '商家',
          soldNum: '已售'
        },
        list: []
      },
      pagination: {
        total: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.getData()
    this.getProductList()
  },
  methods: {
    calcSaleNum(arr) {
      //相加商品中的所有sku销量
      let num = 0
      arr.map(item => (arr += parseInt(item.saleNum)))
      return num
    },
    action(type, row) {
      if (type === 'delete') {
        this.timestamp = new Date().getTime()
        sessionStorage['deleteItemId'] = row.id
      }
    },
    successDelete() {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
      this.getData()
    },
    getData() {
      let params = {
        wxMediaPlatformId: this.wxPid,
        businessName: this.search.business,
        productId: this.search.pid,
        productName: this.search.product,
        page: this.pagination.page,
        size: this.pagination.size
      }
      let p = ''
      Object.keys(params).map(item => {
        p += `${item}=${params[item]}&`
      })
      this.$http
        .get('/boom-mix-biz-service/sysAdmin/wx/media/platform/associate/pageAssociateProductBy?' + p)
        .then(res => {
          this.table.list = res.records
          this.pagination.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getProductList() {
      this.$http
        .post('/boom-center-product-service/sysAdmin/product/list', {
          page: this.dialog.pagination.page,
          size: this.dialog.pagination.size,
          // saleType: 1,
          ...this.dialog.search
        })
        .then(res => {
          this.dialog.list = res.list
          this.dialog.pagination.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    resetProductSearch() {
      this.dialog.search = {
        productId: '',
        productName: '',
        businessName: ''
      }
      this.dialog.pagination.page = 1
      this.getProductList()
    },
    resetSearchRelateProduct() {
      this.search = {
        pid: '',
        product: '',
        business: ''
      }
      this.pagination.page = 1
      this.getData()
    },
    submit() {
      if (this.dialog.selected.id) {
        this.$http
          .post('/boom-center-wechat-service/sysAdmin/wx/media/platform/associate', {
            associateId: this.dialog.selected.id,
            associateType: 1,
            wxMediaPlatformId: this.wxPid
          })
          .then(res => {
            if (!res.msg) {
              this.$message({
                message: res,
                type: 'success'
              })
              this.getData()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            this.dialog.acting = false
            this.dialog.show = false
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.el-button--primary {
  color: #fff !important;
  background-color: #1975ff !important;
  border-color: #1975ff !important;
  margin-left: 20px;
}
.el-button--warning {
  color: #fff !important;
  background-color: #e6a23c !important;
  border-color: #e6a23c !important;
}
.action {
  display: flex;
  .item {
    padding: 0 5px;
    cursor: pointer;
    user-select: none;
    &:active {
      filter: brightness(60%);
    }
  }
}
.img {
  width: 80px;
  height: 80px;
}
.product-img {
  width: 80px;
  height: 80px;
}
.dialogSearch {
  .el-input {
    width: 200px;
    margin-left: 10px;
  }
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #ecf5ff !important;
}
</style>
