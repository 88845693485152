<!--
 * @Author: Link
 * @Date: 2021-01-11 10:27:10
 * @LastEditTime: 2021-03-23 14:51:42
-->
<template>
  <div class="related-area">
    <div class="searchContainer">
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家名称</span>
          <el-input class="searchInput" v-model.trim="search.merchantName" placeholder="商家名称" clearable> </el-input>
        </el-col>

        <el-col :span="8" :offset="8">
          <el-button type="info" @click="resetSearchRelateProduct">重置</el-button>
          <el-button
            type="primary"
            @click="
              pagination.page = 1
              getData()
            "
          >
            搜索</el-button
          ></el-col
        >
      </el-row>
    </div>
    <div class="header">
      <div class="title">关联商家列表</div>
      <el-button type="success" @click="handleAddVendor">添加</el-button>
    </div>
    <el-table :data="table.list" style="margin-top:20px">
      <el-table-column
        align="center"
        v-for="col in Object.keys(table.label)"
        :key="col"
        :label="table.label[col]"
        :prop="col"
      >
        <template slot-scope="{ row }">
          <img class="product-img" v-if="col === 'merchantLogo'" :src="row[col] | thumbMedium" />
          <div v-else-if="col === 'areaIds'">
            {{ row[col] | formatManyAreas }}
          </div>
          <template v-else>{{ row[col] }}</template>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-button @click="action('delete', row)" type="text" size="small" class="deleteBtnText">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pagination.page"
        :page-size="pagination.size"
        :total="pagination.total"
        @current-change="
          v => {
            pagination.page = v
            getData()
          }
        "
        @size-change="
          v => {
            pagination.size = v
            pagination.page = 1
            getData()
          }
        "
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible="dialog.show"
      title="添加关联商家"
      @close="dialog.show = false"
      append-to-body
      width="70%"
      :close-on-click-modal="false"
    >
      <div class="dialogSearch">
        <el-row :gutter="20" class="query">
          <el-col :span="8">
            <span class="searchText">商家ID</span>
            <el-input class="searchInput" v-model.trim="dialog.search.businessId" placeholder="商家ID" clearable>
            </el-input>
          </el-col>

          <el-col :span="8">
            <span class="searchText">商家名称</span>
            <el-input class="searchInput" v-model.trim="dialog.search.searchByName" placeholder="商家名称" clearable>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-button type="info" @click="resetVendorSearch">重置</el-button>
            <el-button
              type="primary"
              @click="
                dialog.pagination.page = 1
                getVendorList()
              "
            >
              搜索</el-button
            ></el-col
          >
        </el-row>
      </div>

      <el-table
        :data="dialog.list"
        style="margin-top:20px"
        highlight-current-row
        @current-change="v => (dialog.selected = v)"
      >
        <el-table-column
          v-for="col in Object.keys(dialog.label)"
          :key="col"
          :prop="col"
          :label="dialog.label[col]"
          align="center"
        >
          <template slot-scope="{ row }">
            <template v-if="col === 'createTime'">
              <div>{{ row.createTime | formatTime }}</div>
            </template>
            <template v-else-if="col === 'areaIds'">
              <div>{{ row.areaIds | formatManyAreas }}</div>
            </template>
            <template v-else>
              {{ row[col] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="dialog.pagination.page"
          :page-size="dialog.pagination.size"
          :total="dialog.pagination.total"
          @current-change="
            v => {
              dialog.pagination.page = v
              getVendorList()
            }
          "
          @size-change="
            v => {
              dialog.pagination.size = v
              dialog.pagination.page = 1
              getVendorList()
            }
          "
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog.show = false">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="dialog.acting">确 定</el-button>
      </div>
    </el-dialog>
    <bm-deleteItem
      url="/boom-center-wechat-service/sysAdmin/wx/media/platform/associate/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
export default {
  props: ['wxPid'],
  data() {
    return {
      timestamp: 0,
      search: {
        merchantName: ''
      },
      dialog: {
        show: false,
        acting: false,
        search: {
          businessId: '',
          searchByName: '',
          businessDistrictId: ''
        },
        selected: {},
        label: {
          businessTitle: '商家名称',
          areaIds: '地区',
          storeCount: '门店数量',
          createTime: '创建时间'
        },
        list: [],
        pagination: {
          total: 0,
          size: 10,
          page: 1
        }
      },
      table: {
        label: {
          merchantLogo: '商家Logo',
          merchantId: '商家ID',
          merchantName: '商家名称',
          areaIds: '地区'
        },
        list: []
      },
      pagination: {
        total: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.getData()
    this.getVendorList()
  },
  methods: {
    handleAddVendor() {
      this.dialog.search.businessId = ''
      this.dialog.search.searchByName = ''
      this.dialog.show = true
      this.getVendorList()
    },
    calcSaleNum(arr) {
      //相加商品中的所有sku销量
      let num = 0
      arr.map(item => (arr += parseInt(item.saleNum)))
      return num
    },
    action(type, row) {
      if (type === 'delete') {
        this.timestamp = new Date().getTime()
        sessionStorage['deleteItemId'] = row.id
      }
    },
    successDelete() {
      this.$message({
        message: '删除成功',
        type: 'success'
      })
      this.getData()
    },
    getData() {
      let params = {
        wxMediaPlatformId: this.wxPid,
        merchantName: this.search.merchantName,
        size: this.pagination.size,
        page: this.pagination.page
      }
      let p = ''
      Object.keys(params).map(item => {
        p += `${item}=${params[item]}&`
      })
      this.$http
        .get('/boom-mix-biz-service/sysAdmin/wx/media/platform/associate/pageAssociateMerchantBy?' + p)
        .then(res => {
          this.table.list = res.records
          this.pagination.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getVendorList(val) {
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          areaId: -1,
          page: this.dialog.pagination.page,
          size: this.dialog.pagination.size,
          ...this.dialog.search
        }
      }
      this.$api.vendor
        .vendor('businessList', params)
        .then(res => {
          this.dialog.list = res.list
          this.dialog.pagination.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetVendorSearch() {
      this.dialog.search = {
        businessId: '',
        searchByName: '',
        businessDistrictId: ''
      }
      this.dialog.pagination.page = 1
      this.getVendorList()
    },
    resetSearchRelateProduct() {
      this.search = {
        merchantName: ''
      }
      this.pagination.page = 1
      this.getData()
    },
    submit() {
      if (this.dialog.selected.id) {
        this.$http
          .post('/boom-center-wechat-service/sysAdmin/wx/media/platform/associate', {
            associateId: this.dialog.selected.id,
            associateType: 3,
            wxMediaPlatformId: this.wxPid
          })
          .then(res => {
            if (!res.msg) {
              this.$message({
                message: res,
                type: 'success'
              })
              this.getData()
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            this.dialog.acting = false
            this.dialog.show = false
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.el-button--primary {
  color: #fff !important;
  background-color: #1975ff !important;
  border-color: #1975ff !important;
  margin-left: 20px;
}
.el-button--warning {
  color: #fff !important;
  background-color: #e6a23c !important;
  border-color: #e6a23c !important;
}
.action {
  display: flex;
  .item {
    padding: 0 5px;
    cursor: pointer;
    user-select: none;
    &:active {
      filter: brightness(60%);
    }
  }
}
.img {
  width: 80px;
  height: 80px;
}
.product-img {
  width: 80px;
  height: 80px;
}
.dialogSearch {
  .el-input {
    width: 200px;
    margin-left: 10px;
  }
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #ecf5ff !important;
}
</style>
