<!--
 * @Author: Link
 * @Date: 2021-01-11 10:05:51
 * @LastEditTime: 2021-01-11 17:56:39
-->
<template>
  <div>
    <el-card class="editContainer">
      <div class="related">
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
          <el-tab-pane name="back">
            <span slot="label"><i class="el-icon-caret-left"></i> 返回</span>
          </el-tab-pane>

          <el-tab-pane label="关联地区" name="area"><relatedArea :wxPid="id"/></el-tab-pane>
          <el-tab-pane label="关联商品" name="product"><relatedProduct :wxPid="id"/></el-tab-pane>
          <el-tab-pane label="关联商家" name="vendor"><relatedVendor :wxPid="id"/></el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>

<script>
import relatedArea from './__com__/relatedArea.vue'
import relatedProduct from './__com__/relatedProduct.vue'
import relatedVendor from './__com__/relatedVendor.vue'
export default {
  components: { relatedArea, relatedProduct, relatedVendor },

  data() {
    return {
      activeName: 'area',
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    handleTabClick({ index }) {
      if (index === '0') {
        this.$router.push('/setting/platform/MediaPlatform')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: calc(100% - 40px);
}
/deep/.el-tabs__item.is-active {
  color: red;
}
/deep/.el-tabs__item:hover {
  color: red;
  cursor: pointer;
}
</style>
