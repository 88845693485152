var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"related-area"},[_c('div',{staticClass:"searchContainer"},[_c('el-row',{staticClass:"query",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商品ID")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商品ID","clearable":""},model:{value:(_vm.search.pid),callback:function ($$v) {_vm.$set(_vm.search, "pid", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.pid"}})],1),_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商品名称")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商品名称","clearable":""},model:{value:(_vm.search.product),callback:function ($$v) {_vm.$set(_vm.search, "product", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.product"}})],1),_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商家名称")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商家名称","clearable":""},model:{value:(_vm.search.business),callback:function ($$v) {_vm.$set(_vm.search, "business", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.business"}})],1)],1),_c('el-row',{staticClass:"query",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8,"offset":16}},[_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.resetSearchRelateProduct}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.pagination.page = 1
            _vm.getData()}}},[_vm._v(" 搜索")])],1)],1)],1),_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("关联商品列表")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.dialog.show = true}}},[_vm._v("添加")])],1),_c('el-table',{staticStyle:{"margin-top":"20px"},attrs:{"data":_vm.table.list}},[_vm._l((Object.keys(_vm.table.label)),function(col){return _c('el-table-column',{key:col,attrs:{"align":"center","label":_vm.table.label[col],"prop":col},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [(col === 'imageUrl')?_c('img',{staticClass:"product-img",attrs:{"src":_vm._f("thumbSmall")(row[col])}}):[_vm._v(_vm._s(row[col]))]]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('el-button',{staticClass:"deleteBtnText",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.action('delete', row)}}},[_vm._v(" 删除 ")])]}}])})],2),_c('div',{staticClass:"pagination"},[_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.pagination.page,"page-size":_vm.pagination.size,"total":_vm.pagination.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"current-change":function (v) {
          _vm.pagination.page = v
          _vm.getData()
        },"size-change":function (v) {
          _vm.pagination.size = v
          _vm.pagination.page = 1
          _vm.getData()
        }}})],1),_c('el-dialog',{attrs:{"visible":_vm.dialog.show,"title":"添加关联商品","append-to-body":"","width":"70%","close-on-click-modal":false},on:{"close":function($event){_vm.dialog.show = false}}},[_c('div',{staticClass:"dialogSearch"},[_c('el-row',{staticClass:"query",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商品ID")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商品ID","clearable":""},model:{value:(_vm.dialog.search.productId),callback:function ($$v) {_vm.$set(_vm.dialog.search, "productId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dialog.search.productId"}})],1),_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商品名称")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商品名称","clearable":""},model:{value:(_vm.dialog.search.productName),callback:function ($$v) {_vm.$set(_vm.dialog.search, "productName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dialog.search.productName"}})],1),_c('el-col',{attrs:{"span":8}},[_c('span',{staticClass:"searchText"},[_vm._v("商家名称")]),_c('el-input',{staticClass:"searchInput",attrs:{"placeholder":"商家名称","clearable":""},model:{value:(_vm.dialog.search.businessName),callback:function ($$v) {_vm.$set(_vm.dialog.search, "businessName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"dialog.search.businessName"}})],1)],1),_c('el-row',{staticClass:"query",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8,"offset":16}},[_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.resetProductSearch}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialog.pagination.page = 1
              _vm.getProductList()}}},[_vm._v(" 搜索")])],1)],1)],1),_c('el-table',{staticStyle:{"margin-top":"20px"},attrs:{"height":"400","data":_vm.dialog.list,"highlight-current-row":""},on:{"current-change":function (v) { return (_vm.dialog.selected = v); }}},_vm._l((Object.keys(_vm.dialog.label)),function(col){return _c('el-table-column',{key:col,attrs:{"prop":col,"label":_vm.dialog.label[col],"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(col === 'productUrl')?_c('img',{staticClass:"img",attrs:{"src":_vm._f("thumbMedium")(row[col])}}):(col === 'info')?[_c('div',[_vm._v("编号："+_vm._s(row.id))]),_c('div',[_vm._v(_vm._s(row.productName))])]:(col === 'saleNum')?[_vm._v(" "+_vm._s(_vm.calcSaleNum(row.productSkuList))+" ")]:(col === 'time')?[_c('div',[_vm._v("售卖开始："+_vm._s(_vm._f("formatTime")(row.saleTimeStart)))]),_c('div',[_vm._v("售卖结束："+_vm._s(_vm._f("formatTime")(row.saleTimeEnd)))]),_c('div',[_vm._v("下架时间："+_vm._s(_vm._f("formatTime")(row.writeOffTimeEnd)))])]:[_vm._v(" "+_vm._s(row[col])+" ")]]}}],null,true)})}),1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{staticClass:"pagination",attrs:{"current-page":_vm.dialog.pagination.page,"page-size":_vm.dialog.pagination.size,"total":_vm.dialog.pagination.total,"layout":"total, sizes, prev, pager, next, jumper"},on:{"current-change":function (v) {
            _vm.dialog.pagination.page = v
            _vm.getProductList()
          },"size-change":function (v) {
            _vm.dialog.pagination.size = v
            _vm.dialog.pagination.page = 1
            _vm.getProductList()
          }}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.dialog.acting},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1),_c('bm-deleteItem',{attrs:{"url":"/boom-center-wechat-service/sysAdmin/wx/media/platform/associate/","timestamp":_vm.timestamp},on:{"successDelete":_vm.successDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }