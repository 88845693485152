import { render, staticRenderFns } from "./relatedProduct.vue?vue&type=template&id=ea1bef40&scoped=true&"
import script from "./relatedProduct.vue?vue&type=script&lang=js&"
export * from "./relatedProduct.vue?vue&type=script&lang=js&"
import style0 from "./relatedProduct.vue?vue&type=style&index=0&id=ea1bef40&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea1bef40",
  null
  
)

export default component.exports